/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Nunito");


/*GECO-5109 Remove x in background for invalid inputs */
.form-control {
    &.is-invalid {
      background-image: none;
      padding-right: .75rem;
    }
  }

  .container-navbar-top {
    .nav-logo {
      &.nav-logo-sm {
        max-width: 4rem;
      }
      &.nav-logo-lg {
        max-width: 10rem;
      }
    }
  }
