// prefix: bui-*

// Booking.com Marketing Palette
// ---
// Booking Blue: #003B95
// Action Blue: #006CE4
// Accent Yellow: #FFB700
// Black: #000000
// White: #FFFFFF

// Booking.com Website Palette
// ---
// Booking Blue: #003580
// Action Blue: #0071C2
// Accent Yellow: #FEBB02
// Accent Orange: #BC5B01
// Accent Gray 1: #F5F5F5
// Accent Gray 2: #E6E6E6
// Black: #333333
// White: #FFFFFF
// ...and others...

// Variables
$enable-gradients: false;

$bui-color-blue-core: #003B95;
$bui-color-blue-action: #006CE4;
$bui-color-yellow-accent: #FFB700;
$bui-color-black-core: #000000;
$bui-color-black-accent: #333333;
$bui-color-gray-core: #F5F5F5;
$bui-color-gray-accent: #E6E6E6;
$bui-color-white: #FFFFFF;

$bui-web-color-blue-core: #003580;
$bui-web-color-blue-action: #0071C2;
$bui-web-color-yellow-accent: #FEBB02;
$bui-web-color-black-core: $bui-color-black-core;
$bui-web-color-black-accent: $bui-color-black-accent;
$bui-web-color-gray-core: $bui-color-gray-core;
$bui-web-color-gray-accent: $bui-color-gray-accent;
$bui-web-color-white: $bui-color-white;

$bui-layout-background-color: $bui-color-white;
$bui-navbar-top-background-color: $bui-color-blue-core;
$bui-card-border-color: $bui-color-gray-accent;
$bui-button-active-background-color: $bui-color-blue-action;
$bui-button-hover-background-color: darken($bui-color-blue-action, 10%);
$bui-button-active-font-color: $bui-color-white;
$bui-link-active-color: darken($bui-color-blue-action, 10%);
$bui-link-hover-color: darken($bui-color-blue-action, 25%);
$bui-text-color: $bui-color-black-accent;
$bui-form-control-border-color: darken($bui-color-gray-accent, 25%);
$bui-form-control-focus-border-color: $bui-color-blue-action;
$bui-form-control-disabled-background-color: $bui-color-gray-core;
$bui-checkbox-background-color: $bui-color-blue-action;
$bui-checkbox-disabled-checked-background-color: $bui-color-gray-accent;
$bui-marquee-icon-color: $bui-color-gray-accent;

//$bui-layout-background-color: $bui-web-color-white;
//$bui-navbar-top-background-color: $bui-web-color-blue-core;
//$bui-card-border-color: $bui-web-color-gray-accent;
//$bui-button-active-background-color: $bui-web-color-blue-action;
//$bui-button-hover-background-color: darken($bui-web-color-blue-action, 10%);
//$bui-button-active-font-color: $bui-web-color-white;
//$bui-link-active-color: darken($bui-web-color-blue-action, 10%);
//$bui-link-hover-color: darken($bui-web-color-blue-action, 25%);
//$bui-text-color: $bui-web-color-black-accent;
//$bui-form-control-border-color: darken($bui-web-color-gray-accent, 25%);
//$bui-form-control-focus-border-color: $bui-web-color-blue-action;
//$bui-form-control-disabled-background-color: $bui-web-color-gray-core;
//$bui-checkbox-background-color: $bui-web-color-blue-action;
//$bui-checkbox-disabled-checked-background-color: $bui-web-color-gray-accent;
//$bui-marquee-icon-color: $bui-web-color-gray-accent;

.container-layout {
  &.bui-container-layout {
    // Page Background
    background-color: $bui-layout-background-color;

    // Text
    color: $bui-text-color;

    h1, .h1,
    h2, .h2,
    h3, .h3 {
      color: $bui-text-color;
    }

    // Top Navbar
    .container-navbar-top {
      background-color: $bui-navbar-top-background-color;
    }

    // Links
    a {
      color: $bui-link-active-color;

      &:active,
      &.active {
        color: $bui-link-active-color;
      }

      &:hover,
      &.hover {
        color: $bui-link-hover-color;
      }

      &:focus,
      &.focus {
        outline: none;
        color: $bui-link-hover-color;
        text-decoration: underline;
      }
    }

    // Buttons
    .btn {
      &.btn-primary {
        &:not(:disabled):not(.disabled),
        &:not(:disabled):not(.disabled) {
          color: $bui-button-active-font-color;
          border-color: $bui-button-active-background-color;
          background-color: $bui-button-active-background-color;

          &:hover {
            color: $bui-button-active-font-color;
            border-color: $bui-button-hover-background-color;
            background-color: $bui-button-hover-background-color;
          }

          &:focus,
          &.focus {
            color: $bui-button-active-font-color;
            border-color: $bui-button-hover-background-color;
            background-color: $bui-button-hover-background-color;

            box-shadow: 0 0 0 0.075rem rgba($bui-button-active-background-color, .5);
          }

          &:active,
          &.active {
            color: $bui-button-active-font-color;
            border-color: $bui-button-active-background-color;
            background-color: $bui-button-active-background-color;
          }
        }
      }
    }

    // Forms & Validation
    .form-group {
      &:not(.form-group-invalid) {
        label {
          color: $bui-text-color;
        }
      }
    }

    // Form Controls (Inputs, Droplists, etc.)
    .form-control {
      &:not(.is-invalid) {
        color: $bui-text-color;
        border-color: $bui-form-control-border-color;

        &:focus {
          border-color: $bui-form-control-focus-border-color;
        }

        &:disabled,
        &.disabled {
          background-color: $bui-form-control-disabled-background-color;
        }
      }
    }

    // Checkbox (Custom)
    .custom-control-input {
      &:not(:disabled):not(.is-invalid) {
        ~.custom-control-label {
          &:before {
            border-color: $bui-form-control-border-color;
          }
        }

        &:checked {
          ~.custom-control-label {
            &:before {
              border-color: $bui-checkbox-background-color;
              background-color: $bui-checkbox-background-color;
            }
          }
        }
      }

      &:disabled:not(.is-invalid) {
        ~.custom-control-label {
          &:before {
            background-color: $bui-form-control-disabled-background-color;
          }
        }

        &:checked {
          ~.custom-control-label {
            &:before {
              border-color: $bui-checkbox-disabled-checked-background-color;
              background-color: $bui-checkbox-disabled-checked-background-color;
            }
          }
        }
      }
    }

    // Cards
    .card {
      border-color: $bui-card-border-color;

      .card-header {
        border-bottom-color: $bui-card-border-color;
      }

      .card-footer {
        border-top-color: $bui-card-border-color;
      }
    }

    // Marquee
    .marquee {
      .marquee-icon {
        color: $bui-marquee-icon-color;
      }
    }
  }
}
